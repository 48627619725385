import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import { getAmrColumns } from '../../amrPipeline/common-columns';
import { Table } from '../../bidding/common/table';
import { AppState } from '../../../types/state/AppState';
import { Portfolio } from '../../../types/portfolio/Portfolio';
import { TableColumnStickType } from '../../bidding/common/table/types/TableColumnStickType';
import { PortfolioSecurity } from '../../../types/portfolio/PortfolioSecurity';
import { constants } from '../../../constants/constants';
import { pipelineColumnLabels, pipelineTooltipText } from '../../../constants/amr-pipeline/pipelineColumnLabels';
import { roles } from '../../../constants/roles';
import { routes } from '../../../constants/routes';
import { bwicStatuses } from '../../../constants/bwic.statuses';
import { BwicStatusLabel } from '../../common/BwicStatusLabel';
import { EmptyPlaceholder } from '../../common/EmptyPlaceholder';
import { OnHoverTooltip } from '../../common/OnHoverTooltip';
import { portfolioActions } from '../../../actions/portfolio.actions';
import { useActiveInventorySecuritesIds } from '../../../effects/useActiveInventorySecuritesIds';
import { history } from '../../../history';
import { Confirm } from '../../alerts';
import IconSVG from '../../../styles/svg-icons';
import { PortfolioOptions } from '../PortfolioOptions';
import { IColumnDefinition } from '../../bidding/common/table/types/ColumnDefinition';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { ColumnBuilder } from '../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { user } from '../../../user/user';
import { StickyTableRow } from '../../bidding/common/table/sticky/StickyTableRow';
import { SecurityInventoryLink } from '../../inventory/SecurityInventoryLink';
import { PortfolioBwicDetails } from './PortfolioBwicDetails';
import { PortfolioLastTraded } from './PortfolioLastTraded';
import { moneyUtils } from '../../../utils/money.utils';
import { formatUtils } from '../../../utils/format.utils';
import { PortfolioSecurityTicker } from './PortfolioSecurityTicker';
import { SameDayBwicIcon } from '../../common/SameDayBwicIcon';
import { BwicProcessType } from '../../../types/models/Process';
import { IntexLink } from '../../common/IntexLink';
import { PortfolioAgreementDisclaimerActionBlocker } from '../../portfolio-agreement-disclaimer/PortfolioAgreementDisclaimerActionBlocker';
import { CreatePortfolioButton } from '../../portfolio-agreement-disclaimer/CreatePortfolioButton';
import { BloombergLink } from '../../common/BloombergLink';
import { SecurityBwicStatistics } from '../../../types/security/SecurityBwicStatistics';
import { PipelineColumn } from "../../amrPipeline/types/PipelineColumn";
import { ValitanaLink } from '../../common/ValitanaLink';
import { collapseStickRightColumn } from '../../bidding/common/table/CollapseStickRightColumn';
import { createColumnListBuilder } from '../../../utils/ColumnListBuilder';
import { PageConfig } from '../../../types/page-config/PageConfig';
import { ColumnConfigKey } from '../../../types/page-config/column-customizer/ColumnConfigKey';
import { MyBwicIcon } from '../../common/MyBwicIcon';
import { DealLookupLink } from '../../amrPipeline/common/DealLookupLink';
import { PortfolioSelectAllCheckbox } from './PortfolioSelectAllCheckbox';
import { SinglePortfolioAlertPreferences } from '../portfolio-alert/SinglePortfolioAlertPreferences';
import { bwicColumnsLabels } from '../../../constants/bwicColumnsLabels';
import { useAppDispatch } from '../../../effects/useAppDispatch';
import { SecurityLastTradedDate } from '../../common/SecurityLastTradedDate';
import { ViewDealDocumentsButton } from '../../documents/deal-documents-popup/ViewDealDocumentButton';

export interface ParsedHistory {
    currentBwic?: SecurityBwicStatistics,
    bwicHistory: SecurityBwicStatistics[],
}

interface TableContext {
    parsedHistory: ParsedHistory
}

export interface Props {
    filteredPortfolios: Portfolio[],
    pageConfig?: PageConfig,
}

const parseSecurityHistory = (history: SecurityBwicStatistics[]): ParsedHistory => {
    const currentBwic = [...history]
        .sort((a, b) => new Date(a.bidsDueUtc).getTime() - new Date(b.bidsDueUtc).getTime())
        .find(h => h.status === bwicStatuses.scheduled.key || h.status === bwicStatuses.bidding.key);
    const bwicHistory = history.filter(h => h.status === bwicStatuses.finished.key);
    return { currentBwic, bwicHistory };
};

export const PortfolioTable = ({ filteredPortfolios, pageConfig }: Props) => {
    const isBroker = user.hasRoles(...roles.bd());
    const columnsConfig = user.hasFeatures(SubscriptionFeature.PortfolioColumnCustomizer)
        ? pageConfig?.columns
        : undefined;

    const dispatch = useAppDispatch();

    const portfolios = useSelector((state: AppState) => state.entities.portfolios.items)
    const selectedState = useSelector((state: AppState) => state.portfolio.selectedState);
    const portfolioDelete = useSelector((state: AppState) => state.portfolio.portfolioDelete);
    const securityDetails = useSelector((s: AppState) => s.securityDetails);
    const activeSecurity = useSelector((s: AppState) => s.securityDetails.security ?? s.bwicLog.security);

    const [, activeInventorySecurityIdByCount] = useActiveInventorySecuritesIds();

    const renderPortfolioSecurities = useCallback((portfolio: Portfolio, columnType?: TableColumnStickType) => {
        const portfolioSelectedSecurities = selectedState[portfolio.id] ? selectedState[portfolio.id].securitiesSelectedState : {};

        const handleSecuritySelect = (securityId: number, selected: boolean) =>
            dispatch(portfolioActions.portfolioSecuritySelectionChange(portfolio.id, securityId, !selected))

        const getPositionListColumns = (
            selectedState: { [securityId: number]: boolean },
            activeInventorySecurityIdByCount: { [securityId: number]: number },
        ): ColumnBuilder<PortfolioSecurity>[][] => {
            const { dealName, collateralType, manager, nonCallPeriodEnd, reinvestmentPeriodEnd, maturity, vintage, trustee, amrDeal, staticDeal, euCompliance, esg, closingDate, outOfNC, outOfRI, nonCallPeriodEndYears, reinvestmentPeriodEndYears } = getAmrColumns();
            const leftColumns: IColumnDefinition<PortfolioSecurity>[] = [{
                columnKey: 'ticker',
                configKey: ColumnConfigKey.Ticker,
                renderColumnHeaderContent: () => '',
                renderColumnContent: (security, context) => (
                    <PortfolioSecurityTicker
                        isSecuritySelected={selectedState[security.id]}
                        security={security}
                        onSecuritySelect={() => handleSecuritySelect(security.id, selectedState[security.id])}
                        currentBwic={context.parsedHistory.currentBwic}
                    />
                ),
                className: 'data-list-cell-lg-02 padding-l-0',
            }];

            const middleColumns: IColumnDefinition<PortfolioSecurity, TableContext>[] = [{
                columnKey: 'deal-documents',
                configKey: ColumnConfigKey.DealDocuments,
                renderColumnHeaderContent: () => 'Docs',
                renderColumnContent: security => security.documentsCount
                    ? <ViewDealDocumentsButton className="text-regular" security={security} />
                    : constants.emptyPlaceholder,
                className: '',
            }, manager, {
                columnKey: 'inventory',
                configKey: ColumnConfigKey.Inventory,
                renderColumnHeaderContent: () => '',
                renderColumnContent: security =>
                    !!activeInventorySecurityIdByCount[security.securityId] ? (
                        <SecurityInventoryLink
                            count={activeInventorySecurityIdByCount[security.securityId]}
                            ticker={security.ticker}
                        />
                    ) : (constants.emptyPlaceholder),
                className: 'data-list-cell-sm',
            }, {
                columnKey: 'rtg',
                configKey: ColumnConfigKey.Rating,
                renderColumnHeaderContent: () => '',
                renderColumnContent: security => security.rating,
                className: 'data-list-cell-xs',
            }, {
                columnKey: 'size',
                configKey: ColumnConfigKey.Size,
                renderColumnHeaderContent: () => '',
                renderColumnContent: security => moneyUtils.amountToMM(security.size),
                className: 'text-right',
            }, {
                columnKey: 'is-my-bwic',
                configKey: ColumnConfigKey.CurrentBwic,
                renderColumnHeaderContent: () => '',
                renderColumnContent: (security, context) => context.parsedHistory.currentBwic ? (
                    <MyBwicIcon bwic={context.parsedHistory.currentBwic} />
                ) : null,
                className: 'data-list-cell-xxs',
            }, {
                columnKey: 'current-bwic',
                configKey: ColumnConfigKey.CurrentBwic,
                renderColumnHeaderContent: () => '',
                renderColumnContent: (security, context) => context.parsedHistory.currentBwic ? (
                    <BwicStatusLabel
                        status={context.parsedHistory.currentBwic.status}
                        liveBidding={context.parsedHistory.currentBwic.process?.type === BwicProcessType.Live}
                        isParsed={context.parsedHistory.currentBwic.isParsed}
                        directBidding={context.parsedHistory.currentBwic.isClearingBankParticipant}
                        isAllToAll={context.parsedHistory.currentBwic.isAllToAll}
                    />
                ) : (constants.emptyPlaceholder),
                className: 'data-list-cell-lg',
            }, {
                columnKey: 'same-day',
                renderColumnHeaderContent: () => '',
                configKey: ColumnConfigKey.CurrentBwic,
                renderColumnContent: (security, context) => (
                    context.parsedHistory.currentBwic?.isSameDayBwic && <SameDayBwicIcon />
                ),
                className: 'data-list-cell-xxs',
            }, {
                columnKey: 'currentBWIC',
                configKey: ColumnConfigKey.CurrentBwic,
                renderColumnHeaderContent: () => '',
                renderColumnContent: (security, context) => {
                    return (
                        <PortfolioBwicDetails security={security} currentBwic={context.parsedHistory.currentBwic} />
                    )
                },
                className: 'data-list-cell-lg',
            }, {
                columnKey: 'lastTraderIsMine',
                configKey: ColumnConfigKey.LastTraded,
                renderColumnHeaderContent: () => '',
                renderColumnContent: (security, context) => (
                    <SecurityLastTradedDate inventoryLastTraded={security.inventoryLastTradeDate} bwicLastTradedDate={context.parsedHistory.bwicHistory?.[0]?.bidsDueUtc}>
                        {(isInventoryDate) => context.parsedHistory.bwicHistory?.[0] && !isInventoryDate ? <MyBwicIcon bwic={context.parsedHistory.bwicHistory[0]} /> : null}
                    </SecurityLastTradedDate>
                ),
                className: 'data-list-cell-xxs',
            }, {
                columnKey: 'dueDate',
                configKey: ColumnConfigKey.LastTraded,
                renderColumnHeaderContent: () => '',
                renderColumnContent: (security, context) => (
                    <SecurityLastTradedDate inventoryLastTraded={security.inventoryLastTradeDate} bwicLastTradedDate={context.parsedHistory.bwicHistory?.[0]?.bidsDueUtc}>
                        {(isInventoryDate, lastTradeDate) => lastTradeDate ? moment(lastTradeDate).format(constants.dateFormat) : constants.emptyPlaceholder}
                    </SecurityLastTradedDate>
                ),
                className: 'data-list-cell-sm',
            }, {
                columnKey: 'lastTraded',
                configKey: ColumnConfigKey.LastTraded,
                renderColumnHeaderContent: () => '',
                renderColumnContent: (security, context) =>
                    <SecurityLastTradedDate inventoryLastTraded={security.inventoryLastTradeDate} bwicLastTradedDate={context.parsedHistory.bwicHistory?.[0]?.bidsDueUtc}>
                        {(isInventoryDate) => isInventoryDate ? null : <PortfolioLastTraded bwicHistory={context.parsedHistory.bwicHistory} />}
                    </SecurityLastTradedDate>
            }, {
                columnKey: 'colorLastTraded',
                configKey: ColumnConfigKey.LastTraded,
                renderColumnHeaderContent: () => '',
                renderColumnContent: (security, context) => (
                    <SecurityLastTradedDate inventoryLastTraded={security.inventoryLastTradeDate} bwicLastTradedDate={context.parsedHistory.bwicHistory?.[0]?.bidsDueUtc}>
                        {(isInventoryDate) => {
                            if (!context.parsedHistory?.bwicHistory?.[0]?.color || isInventoryDate) return null;
                            const [, levelSpecification, level] = formatUtils.formatColor(context.parsedHistory.bwicHistory[0].color).split(' ');
                            return <>{levelSpecification} {level}</>
                        }}
                    </SecurityLastTradedDate>
                ),
                className: 'data-list-cell-sm-01',
            },
                dealName,
                closingDate,
                nonCallPeriodEnd,
                nonCallPeriodEndYears,
                outOfNC,
                reinvestmentPeriodEnd,
                reinvestmentPeriodEndYears,
                outOfRI,
                maturity,
                vintage,
                trustee,
                amrDeal,
                staticDeal,
                euCompliance,
                esg,
                collateralType
            ];

            const imDealLookup: IColumnDefinition<PortfolioSecurity> = {
                columnKey: 'imDealLookup',
                renderColumnHeaderContent: () => null,
                renderColumnContent: security =>
                    <>
                        {
                            !!security.dealReferenceName &&
                            !!security.dealLegalName &&
                            <DealLookupLink
                                dealReferenceName={security.dealReferenceName}
                                dealLegalName={security.dealLegalName}
                            />
                        }
                    </>,
                className: 'data-list-cell-xxs margin-l-a padding-r-0 text-right'
            };
            const intex: IColumnDefinition<PortfolioSecurity> = {
                columnKey: 'intex',
                renderColumnHeaderContent: () => '',
                renderColumnContent: security => <IntexLink positionId={security.isinCusip} />,
                className: 'data-list-cell-xxs text-right padding-r-0',
            };
            const bloomberg: IColumnDefinition<PortfolioSecurity> = {
                columnKey: 'bloomberg',
                renderColumnHeaderContent: () => '',
                renderColumnContent: security => <BloombergLink identifier={security.isinCusip} />,
                className: 'data-list-cell-xxs text-right padding-r-0',
            };
            const valitana: IColumnDefinition<PortfolioSecurity> = {
                columnKey: 'valitana',
                configKey: ColumnConfigKey.Actions,
                renderColumnHeaderContent: () => 'Actions',
                renderColumnContent: security => <ValitanaLink identifier={security.isinCusip} />,
                className: 'data-list-cell-xxs text-right',
            };

            const left = createColumnListBuilder<PortfolioSecurity>(columnsConfig)
                .add(...leftColumns)
                .result()
                .map(c => new ColumnBuilder(c));
            const middle = createColumnListBuilder<PortfolioSecurity>(columnsConfig)
                .add(...middleColumns)
                .result()
                .map(c => new ColumnBuilder(c));
            const right = createColumnListBuilder<PortfolioSecurity>(columnsConfig)
                .add(collapseStickRightColumn)
                .addWhen(() => user.hasRoles(...roles.issuanceMonitorAccess()), imDealLookup)
                .add(intex, bloomberg, valitana)
                .result()
                .map(c => new ColumnBuilder(c));

            return [left, middle, right];
        }

        const [left, middle, right] = getPositionListColumns(
            portfolioSelectedSecurities,
            activeInventorySecurityIdByCount
        );

        let columns: ColumnBuilder<PortfolioSecurity>[] = [];

        if (columnType === TableColumnStickType.Left) columns = left;
        if (columnType === TableColumnStickType.Middle) columns = middle;
        if (columnType === TableColumnStickType.Right) columns = right;

        return portfolio.securities.map(s => (
            <StickyTableRow
                key={s.id}
                rowKey={String(s.id)}
                columns={columns}
                dataItem={s}
                className={classNames({ active: s.id === activeSecurity?.id })}
                context={{ parsedHistory: parseSecurityHistory(s.history) }}
            />
        ));
    }, [selectedState, activeInventorySecurityIdByCount, activeSecurity, dispatch, columnsConfig])

    const getPortfolioTableColumns = useMemo(() => {
        const ticker = {
            columnKey: 'ticker',
            configKey: ColumnConfigKey.Ticker,
            renderColumnHeaderContent: () => 'Ticker',
            renderColumnContent: (portfolio: Portfolio) =>
                <PortfolioSelectAllCheckbox
                    portfolio={portfolio}
                    selectedPortfolioState={selectedState[portfolio.id]}
                />,
            headerClassName: 'data-list-cell-lg-02 padding-l-0',
            bodyClassName: 'data-list-cell-portfolio-ticker padding-l-0',
            stickLeft: true
        };
        const inventory = {
            columnKey: 'inventory',
            configKey: ColumnConfigKey.Inventory,
            renderColumnHeaderContent: () => (
                <>
                    Inventory
                    <OnHoverTooltip
                        overlay={`Click on ‘View’ to see this security in the ${isBroker ? '' : 'Dealer'} Inventory`}
                    >
                        <IconSVG name="info" width={16} height={16} />
                    </OnHoverTooltip>
                </>
            ),
            renderColumnContent: () => '',
            headerClassName: 'data-list-cell-sm',
            bodyClassName: 'data-list-cell-symbol',
        }
        const rtg = {
            columnKey: 'rtg',
            configKey: ColumnConfigKey.Rating,
            renderColumnHeaderContent: () => 'Rtg',
            renderColumnContent: () => '',
            headerClassName: 'data-list-cell-xs',
            bodyClassName: 'data-list-cell-symbol',
        }
        const size = {
            columnKey: 'size',
            configKey: ColumnConfigKey.Size,
            renderColumnHeaderContent: () => 'Size, MM',
            renderColumnContent: () => '',
            headerClassName: 'text-right',
            bodyClassName: 'data-list-cell-symbol text-right',
        }
        const currentBWICmyBWIC = {
            columnKey: 'is-my-bwic',
            configKey: ColumnConfigKey.CurrentBwic,
            renderColumnHeaderContent: () => '',
            renderColumnContent: () => '',
            className: 'data-list-cell-xxs',
        }
        const currentBWIC = {
            columnKey: 'currentBWIC',
            configKey: ColumnConfigKey.CurrentBwic,
            renderColumnHeaderContent: () => 'Current BWIC',
            renderColumnContent: () => '',
            className: 'data-list-cell-lg',
        }
        const sameDay = {
            columnKey: 'same-day',
            configKey: ColumnConfigKey.CurrentBwic,
            renderColumnHeaderContent: () => '',
            renderColumnContent: () => '',
            className: 'data-list-cell-xxs',
        }
        const currentBWICdate = {
            columnKey: 'currentBWICdate',
            configKey: ColumnConfigKey.CurrentBwic,
            renderColumnHeaderContent: () => '',
            renderColumnContent: () => '',
            className: 'data-list-cell-lg',
        }
        const lastTraderIsMine = {
            columnKey: 'lastTraderIsMine',
            configKey: ColumnConfigKey.LastTraded,
            renderColumnHeaderContent: () => '',
            renderColumnContent: () => '',
            className: 'data-list-cell-xxs',
        }
        const lastTraded = {
            columnKey: 'lastTraded',
            configKey: ColumnConfigKey.LastTraded,
            renderColumnHeaderContent: () => 'Last Traded',
            renderColumnContent: () => '',
            className: 'data-list-cell-sm',
        }
        const lastTradedStatus = {
            columnKey: 'lastTradedStatus',
            configKey: ColumnConfigKey.LastTraded,
            renderColumnHeaderContent: () => '',
            renderColumnContent: () => '',
        }
        const lastTradedColor = {
            columnKey: 'lastTradedColor',
            configKey: ColumnConfigKey.LastTraded,
            renderColumnHeaderContent: () => '',
            renderColumnContent: () => '',
            className: 'data-list-cell-sm-01',
        }
        const dealName = {
            columnKey: 'dealName',
            configKey: ColumnConfigKey.DealName,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.dealName],
            renderColumnContent: () => '',
            className: 'data-list-cell-xl',
        }
        const manager = {
            columnKey: 'manager',
            configKey: ColumnConfigKey.CollateralManager,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.manager],
            renderColumnContent: () => '',
            className: 'data-list-cell-xl',
        }
        const closingDate = {
            columnKey: 'closingDate',
            configKey: ColumnConfigKey.ClosingDate,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.closingDate],
            renderColumnContent: () => '',
            className: 'data-list-cell-sm',
        }
        const ncEnd = {
            columnKey: 'NcEnd',
            configKey: ColumnConfigKey.NcEnd,
            renderColumnHeaderContent: () => bwicColumnsLabels[ColumnConfigKey.NcEnd],
            renderColumnContent: () => '',
            className: "data-list-cell-sm"
        }
        const riEnd = {
            columnKey: 'RiEnd',
            configKey: ColumnConfigKey.RiEnd,
            renderColumnHeaderContent: () => bwicColumnsLabels[ColumnConfigKey.RiEnd],
            renderColumnContent: () => '',
            className: "data-list-cell-sm"
        }
        const ncEndYrs = {
            columnKey: 'ncEndYrs',
            configKey: ColumnConfigKey.NcEndYrs,
            renderColumnHeaderContent: () => (
                <OnHoverTooltip overlay={pipelineTooltipText[PipelineColumn.nonCallPeriodEnd]}>
                    {pipelineColumnLabels[PipelineColumn.nonCallPeriodEndYears]}
                    <IconSVG name="info" width={16} height={16} />
                </OnHoverTooltip>
            ),
            renderColumnContent: () => '',
            className: "data-list-cell-sm text-right"
        }
        const outOfNcEnd = {
            columnKey: 'outOfNcEnd',
            configKey: ColumnConfigKey.OutOfNc,
            renderColumnHeaderContent: () => (
                <OnHoverTooltip overlay={pipelineTooltipText[PipelineColumn.outOfNC]}>
                    {pipelineColumnLabels[PipelineColumn.outOfNC]}
                    <IconSVG name="info" width={16} height={16} />
                </OnHoverTooltip>
            ),
            renderColumnContent: () => '',
            className: "data-list-cell-sm"
        }
        const riEndYrs = {
            columnKey: 'riEndYrs',
            configKey: ColumnConfigKey.RiEndYrs,
            renderColumnHeaderContent: () => (
                <OnHoverTooltip overlay={pipelineTooltipText[PipelineColumn.reinvestmentPeriodEnd]}>
                    {pipelineColumnLabels[PipelineColumn.reinvestmentPeriodEndYears]}
                    <IconSVG name="info" width={16} height={16} />
                </OnHoverTooltip>
            ),
            renderColumnContent: () => '',
            className: "data-list-cell-sm text-right"
        }
        const outOfRiEnd = {
            columnKey: 'outOfRiEnd',
            configKey: ColumnConfigKey.OutOfRi,
            renderColumnHeaderContent: () => (
                <OnHoverTooltip overlay={pipelineTooltipText[PipelineColumn.outOfRI]}>
                    {pipelineColumnLabels[PipelineColumn.outOfRI]}
                    <IconSVG name="info" width={16} height={16} />
                </OnHoverTooltip>
            ),
            renderColumnContent: () => '',
            className: "data-list-cell-sm"
        }
        const maturity = {
            columnKey: 'maturity',
            configKey: ColumnConfigKey.Maturity,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.maturity],
            renderColumnContent: () => '',
            className: 'data-list-cell-sm'
        }
        const vintage = {
            columnKey: 'vintage',
            configKey: ColumnConfigKey.Vintage,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.vintage],
            renderColumnContent: () => '',
        }
        const trustee = {
            columnKey: 'trustee',
            configKey: ColumnConfigKey.Trustee,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.trustee],
            renderColumnContent: () => '',
            className: 'data-list-cell-xl',
        }
        const amrDeal = {
            columnKey: 'amrDeal',
            configKey: ColumnConfigKey.AmrDeal,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.amrDeal],
            renderColumnContent: () => '',
            className: 'data-list-cell-sm',
        }
        const staticDeal = {
            columnKey: 'staticDeal',
            configKey: ColumnConfigKey.StaticDeal,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.staticDeal],
            renderColumnContent: () => '',
            className: 'data-list-cell-sm',
        }
        const euComp = {
            columnKey: 'euComp',
            configKey: ColumnConfigKey.EuCompliance,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.euCompliance],
            renderColumnContent: () => '',
            className: 'data-list-cell-sm',
        }
        const esg = {
            columnKey: 'esg',
            configKey: ColumnConfigKey.Esg,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.esg],
            renderColumnContent: () => '',
            className: 'data-list-cell-xs',
        }
        const collat = {
            columnKey: 'collat',
            configKey: ColumnConfigKey.CollateralType,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.collateralType],
            renderColumnContent: () => '',
            className: 'data-list-cell-xs',
        }
        const dealDocs = {
            columnKey: 'deal-documents',
            configKey: ColumnConfigKey.DealDocuments,
            renderColumnHeaderContent: () => 'Docs',
            renderColumnContent: () => '',
            className: '',
        }
        const imDealLookupPlaceholder = {
            columnKey: 'imDealLookupPlaceholder',
            renderColumnHeaderContent: () => '',
            renderColumnContent: () => '',
            className: 'data-list-cell-xxs',
            stickRight: true
        }
        const options = {
            columnKey: 'options',
            renderColumnHeaderContent: () => 'Actions',
            renderColumnContent: (portfolio: Portfolio) => (
                <div className="flex-row">
                    <div className="flex-item-right flex-row">
                        <div className="portfolio-alert-selector">
                            <SinglePortfolioAlertPreferences portfolio={portfolio} />
                        </div>
                        <PortfolioOptions portfolio={portfolio} />
                    </div>
                </div>
            ),
            headerClassName: 'data-list-cell-sm text-right',
            bodyClassName: 'data-list-cell-sm text-right overflow-visible',
            stickRight: true
        }

        const columnDefinitions: IColumnDefinition<Portfolio>[] = createColumnListBuilder<Portfolio>(columnsConfig)
            .add(ticker)
            .add(dealDocs)
            .add(manager)
            .add(inventory)
            .add(rtg)
            .add(size)
            .add(currentBWICmyBWIC)
            .add(currentBWIC)
            .add(sameDay)
            .add(currentBWICdate)
            .add(lastTraderIsMine, lastTraded, lastTradedStatus, lastTradedColor)
            .add(dealName)
            .add(closingDate)
            .add(ncEnd)
            .add(ncEndYrs)
            .add(outOfNcEnd)
            .add(riEnd)
            .add(riEndYrs)
            .add(outOfRiEnd)
            .add(maturity)
            .add(vintage)
            .add(trustee)
            .add(amrDeal)
            .add(staticDeal)
            .add(euComp)
            .add(esg)
            .add(collat)
            .add(collapseStickRightColumn)
            .addWhen(() => user.hasRoles(...roles.issuanceMonitorAccess()), imDealLookupPlaceholder)
            .add(options)
            .result()

        return columnDefinitions.map(c => new ColumnBuilder(c));
        // eslint-disable-next-line
    }, [selectedState, isBroker, securityDetails, pageConfig?.columns])

    if (!portfolios) {
        return null;
    }

    if (!portfolios.length) {
        const handleClick = () => history.push(routes.newPortfolio);

        return (
            <EmptyPlaceholder
                title="No Portfolio Created"
                text={
                    <>
                        You have not created any portfolios yet.
                        <br />
                        To create a portfolio, click "Add Portfolio" below.
                    </>
                }
            >
                <PortfolioAgreementDisclaimerActionBlocker onConfirm={handleClick}>
                    <CreatePortfolioButton className="btn btn-main" onClick={handleClick}>
                        Add portfolio
                    </CreatePortfolioButton>
                </PortfolioAgreementDisclaimerActionBlocker>
            </EmptyPlaceholder>
        )
    }

    return (
        <>
            <Table
                dataItems={filteredPortfolios}
                columns={getPortfolioTableColumns}
                renderCollapsibleItem={renderPortfolioSecurities}
                collapsible={true}
                sticky={true}
                defaultExpandAll={true}
            />
            {
                portfolioDelete.visible &&
                <Confirm
                    text={`Please confirm you would like to delete ${portfolioDelete.portfolio.name}.`}
                    onCancel={() => dispatch(portfolioActions.deletePortfolioConfirmation(false))}
                    onConfirm={() => dispatch(portfolioActions.deletePortfolio(portfolioDelete.portfolio.id))}
                />
            }
        </>
    )
}
